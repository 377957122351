import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'

export type FieldResult = { values: number[]; karmique: boolean }

export type NumerologyResult = {
    wayOfLife: FieldResult
    angularStone: FieldResult
    hereditary: FieldResult
    personalYear: FieldResult
    firstName1: {
        active: FieldResult
        intimate: FieldResult
        realization: FieldResult
        expression: FieldResult
    }
    allFirstNames: {
        active: FieldResult
        intimate: FieldResult
        realization: FieldResult
        expression: FieldResult
    }
    inclusionTables: {
        shortName: number[]
        fullName: number[]
    }
}
function createEmptyResult(): NumerologyResult {
    return {
        wayOfLife: { values: [], karmique: false },
        angularStone: { values: [], karmique: false },
        hereditary: { values: [], karmique: false },
        personalYear: { values: [], karmique: false },
        firstName1: {
            active: { values: [], karmique: false },
            intimate: { values: [], karmique: false },
            realization: { values: [], karmique: false },
            expression: { values: [], karmique: false },
        },
        allFirstNames: {
            active: { values: [], karmique: false },
            intimate: { values: [], karmique: false },
            realization: { values: [], karmique: false },
            expression: { values: [], karmique: false },
        },
        inclusionTables: {
            shortName: new Array(9).fill(0),
            fullName: new Array(9).fill(0),
        },
    }
}
type State = {
    dayOfBirth: string
    lastName: string
    firstName1: string
    firstName2: string
    firstName3: string
    firstName4: string
    results: NumerologyResult
}

type Actions = {
    setLastName(lastName: string): void
    setDayOfBirth(dayOfBirth: string): void
    setFirstName1(firstName: string): void
    setFirstName2(firstName: string): void
    setFirstName3(firstName: string): void
    setFirstName4(firstName: string): void
}

const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
const vowels = ['A', 'E', 'I', 'O', 'U', 'Y']

function removeDiacritics(str: string) {
    return str.normalize('NFD').replace(/\p{Diacritic}/gu, '')
}

function getNumberForLetter(letter: string) {
    const normalized = removeDiacritics(letter).toUpperCase()
    if (!alphabet.includes(normalized)) return 0
    return (alphabet.indexOf(normalized) % 9) + 1
}

function isKarmique(num: number[], name: string) {
    const last = num[num.length - 1]
    const validLetters = alphabet.split('').filter((_, i) => (i % 9) + 1 === last)
    return !validLetters.some((letter) => name.includes(letter))
}

function computeIntermediateNumbers(num: number) {
    if (num < 10) return [num]
    const values: number[] = [num]
    while (values[values.length - 1] >= 10) {
        // Sum the digits composing the number
        values.push(
            values[values.length - 1]
                .toString()
                .split('')
                .reduce((a, b) => a + parseInt(b, 10), 0),
        )
    }
    return values
}

function computeInclusionTable(name: string) {
    const inclusionTable = new Array(9).fill(0)
    for (const char of name.split('')) {
        const nb = getNumberForLetter(char)
        inclusionTable[nb - 1] += 1
    }
    return inclusionTable
}

function computeResultsForState(state: State): NumerologyResult {
    const results: NumerologyResult = createEmptyResult()

    const fullNormalName = removeDiacritics(state.firstName1 + state.lastName)

    // Compute way of life
    const [year, month, day] = state.dayOfBirth.split('-')
    if (year && month && day) {
        const values = computeIntermediateNumbers(
            [year, month, day]
                .flatMap((n) => n.split(''))
                .map((n) => parseInt(n, 10))
                .reduce((a, b) => a + b, 0),
        )
        results.wayOfLife = { values, karmique: isKarmique(values, fullNormalName) }
    }
    const allFirstNames = [state.firstName1, state.firstName2, state.firstName3, state.firstName4]
        .flatMap((firstName) => (firstName ? [firstName] : []))
        .join('')
    // Compute angular stone
    if (state.firstName1) {
        const values = computeIntermediateNumbers(getNumberForLetter(state.firstName1[0]))
        results.angularStone = { values, karmique: isKarmique(values, fullNormalName) }
    }
    // Compute active number
    if (state.firstName1) {
        const activeValues = computeIntermediateNumbers(
            state.firstName1.split('').reduce((a, b) => a + getNumberForLetter(b), 0),
        )
        results.firstName1.active = {
            values: activeValues,
            karmique: isKarmique(activeValues, fullNormalName),
        }
        const allFirstNamesValues = computeIntermediateNumbers(
            allFirstNames.split('').reduce((a, b) => a + getNumberForLetter(b), 0),
        )
        results.allFirstNames.active = { values: allFirstNamesValues, karmique: false }
    }
    // Compute hereditary number
    if (state.lastName.length > 0) {
        const values = computeIntermediateNumbers(
            state.lastName.split('').reduce((a, b) => a + getNumberForLetter(b), 0),
        )
        results.hereditary = { values, karmique: false }
    }
    // Compute intimate and realization number
    if (state.firstName1 && state.lastName.length > 0) {
        let fullName = (state.firstName1 + state.lastName).split('').map(removeDiacritics)
        const intimateValues = computeIntermediateNumbers(
            fullName
                .filter((letter) => vowels.includes(letter.toUpperCase()))
                .map((n) => getNumberForLetter(n))
                .reduce((a, b) => a + b, 0),
        )
        results.firstName1.intimate = {
            values: intimateValues,
            karmique: isKarmique(intimateValues, fullNormalName),
        }
        const realizationValues = computeIntermediateNumbers(
            fullName
                .filter((letter) => !vowels.includes(letter.toUpperCase()))
                .map((n) => getNumberForLetter(n))
                .reduce((a, b) => a + b, 0),
        )
        results.firstName1.realization = {
            values: realizationValues,
            karmique: isKarmique(realizationValues, fullNormalName),
        }
        fullName = (allFirstNames + state.lastName).split('').map(removeDiacritics)
        const intimateValuesAllFirstNames = computeIntermediateNumbers(
            fullName
                .filter((letter) => vowels.includes(letter.toUpperCase()))
                .map((n) => getNumberForLetter(n))
                .reduce((a, b) => a + b, 0),
        )
        results.allFirstNames.intimate = { values: intimateValuesAllFirstNames, karmique: false }
        const realizationValuesAllFirstNames = computeIntermediateNumbers(
            fullName
                .filter((letter) => !vowels.includes(letter.toUpperCase()))
                .map((n) => getNumberForLetter(n))
                .reduce((a, b) => a + b, 0),
        )
        results.allFirstNames.realization = {
            values: realizationValuesAllFirstNames,
            karmique: false,
        }
    }
    // Compute expression number
    const [hereditary] = results.hereditary.values.slice(
        Math.max(results.hereditary.values.length - 2, 0),
    )
    if (results.firstName1.active.values.length > 0 && results.hereditary.values.length > 0) {
        const [activeFirstName] = results.firstName1.active.values.slice(
            Math.max(results.firstName1.active.values.length - 2, 0),
        )
        const firstName1Expression = computeIntermediateNumbers(activeFirstName + hereditary)
        results.firstName1.expression = { values: firstName1Expression, karmique: false }
        const [activeAllFirstNames] = results.allFirstNames.active.values.slice(
            Math.max(results.allFirstNames.active.values.length - 2, 0),
        )
        const allFirstNamesExpression = computeIntermediateNumbers(activeAllFirstNames + hereditary)
        results.allFirstNames.expression = { values: allFirstNamesExpression, karmique: false }
    }
    // Compute personal year
    if (day && month) {
        const currentYear = new Date().getFullYear()
        const personalYearValues = computeIntermediateNumbers(
            [day, month, currentYear.toString()]
                .flatMap((n) => n.split(''))
                .map((n) => parseInt(n, 10))
                .reduce((a, b) => a + b, 0),
        )
        results.personalYear = { values: personalYearValues, karmique: false }
    }
    // Compute inclusion tables
    results.inclusionTables = {
        shortName: computeInclusionTable(state.firstName1 + state.lastName),
        fullName: computeInclusionTable(allFirstNames + state.lastName),
    }
    return results
}

export const useNumerologyStore = create<State & Actions>()(
    immer((set) => ({
        dayOfBirth: '',
        lastName: '',
        firstName1: '',
        firstName2: '',
        firstName3: '',
        firstName4: '',
        results: createEmptyResult(),
        setLastName: (lastName) =>
            set((state) => {
                state.lastName = lastName
                state.results = computeResultsForState(state)
            }),
        setDayOfBirth: (dayOfBirth) =>
            set((state) => {
                state.dayOfBirth = dayOfBirth
                state.results = computeResultsForState(state)
            }),
        setFirstName1: (firstName1) =>
            set((state) => {
                state.firstName1 = firstName1
                state.results = computeResultsForState(state)
            }),
        setFirstName2: (firstName2) =>
            set((state) => {
                state.firstName2 = firstName2
                state.results = computeResultsForState(state)
            }),
        setFirstName3: (firstName3) =>
            set((state) => {
                state.firstName3 = firstName3
                state.results = computeResultsForState(state)
            }),
        setFirstName4: (firstName4) =>
            set((state) => {
                state.firstName4 = firstName4
                state.results = computeResultsForState(state)
            }),
    })),
)
